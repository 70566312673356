// global.js 文件
export default {
    ws: {},
	no:1,
    setWs: function(newWs) {		
        this.ws = newWs
    },
	setNo: function(newNo) {
	    this.no = newNo
	}
}