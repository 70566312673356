<template>
  <div>
    <transition name="fade-user">
      <div class="previewBox">
        <el-button
          class="drawer-close"
          type="danger"
          @click="closeDrawer"
          icon="el-icon-close"
          circle
        ></el-button>
		<div v-if="type == 'image'" class="pics">
			<img :src="showurl"/>
		</div>
		<div class="previewfile" v-else-if="type == 'file'">
			<div class="download"><a :href="showurl" target="_blank">下载</a></div>
		   <div class="file" v-if="ext == 'txt'">
		       <div class="txt" style="white-space: pre-wrap;">{{ textContent }}</div>
		   </div>
		   <div class="file" v-else-if="ext == 'doc' || ext == 'docx'">			   
		         <vue-office-docx  :src="showurl" class="docx-calss"  @rendered="onRendered"  />
		   </div>
		   <div class="file" v-else-if="ext == 'xls' || ext == 'xlsx'">
			   <vue-office-excel class="docx-calss" :src="showurl" @rendered="onRendered"/>
			</div>
			<div class="file" v-else-if="ext == 'pdf'">				
				 <vue-office-pdf class="docx-calss" :src="showurl" @rendered="onRendered"/>
			</div>
		</div>
		<div v-else-if="type == 'video'">
				<div  class="sx-xgplayer-wrapper change-y-translate">
		            <div class="video-section">						
		                <div ref="video" id="mmid" class="vid"></div>
		                <span class="icon-close" @click="closeDrawer()">X</span>
		            </div>
		        </div>		
		</div>
		<div v-else>
			<iframe
			  :src="showurl"
			  frameborder="0"
			  width="100%"
			  height="100%"
			></iframe>
		</div>
      </div>
    </transition>
  </div>
</template>

<script>
	import VueOfficeDocx from '@vue-office/docx'
	import '@vue-office/docx/lib/index.css'
	import VueOfficeExcel from '@vue-office/excel'
	import '@vue-office/excel/lib/index.css'
	import VueOfficePdf from '@vue-office/pdf'

	import axios from 'axios';
	
	import Player, { Events } from 'xgplayer'
	import 'xgplayer/dist/index.min.css';
	import { onMounted,onUnmounted } from 'vue';
	var tplayer = null;
	export default {
	  name: "preview",
	  props: {
	    url:{
	       type:String,
	       default:''
			},
		options:{
	            type: Object,
	            default: () => [],
	        }
	  },
	  components:{
	      VueOfficeDocx,
		  VueOfficeExcel,
		  VueOfficePdf
	    },
	  data() {
	    return {
			showurl:null,
			type:null,
			ext:null,
			textContent:null
	    };
	  },
	  mounted() {
		this.getFiletype() 	  
	  },
	  methods: {
		  onRendered(){
			  console.log("渲染完成");
		  },
		  getFiletype(){
			  var options = this.options;
			  this.showurl = this.url;
			  this.type = options.type;
			  this.ext = options.ext;
			  //console.log('options:',options,this.url);
			  if(options.ext == "txt"){
				    axios.get(this.url,{
				          responseType:"text",
				      }).then(res=>{
				          this.textContent = res.data;
				      })
	
			  }else if(options.type == "video"){
					this.subSomething()
					//player.play()
			  }
			  
		  },
		  subSomething(){
			  this.$nextTick(() => {
						if (document.readyState == "complete"){
								tplayer = new Player({
										          el: document.querySelector('#mmid'),
										          url: this.url,
										  		  fluid: true,
										          height: '100%',
										          width: '100%',
										          lang:'zh-cn'
									})
						}else if(document.readyState=="loading"){
										  
						}
				  })			  
		  },
		  onUnmounted(){
		          tplayer.destroy() // 销毁播放器
		          tplayer = null // 将实例引用置空
		      },
		  closeDrawer(){
	        this.$emit('close');
		  }
	  }
	};
</script>
<style scoped lang="scss">
.sx-xgplayer-wrapper{
	        position:fixed;
	        width:100%;
	        height:100%;
	        background:rgba(0,0,0,0.1);
	        overflow:hidden;
	        top:0;
	        left:0;
	        z-index:1;
	        display:flex;
	        justify-content:center;
	        align-items:center;	 
	        .video-section{
	            width:1200px;
	            height:660px;
	            background:#000;
	            padding:20px;
				z-index: 1;
	            position:relative;
	            box-shadow: 0 1px 8px 1px #5c5c5c;	        
				.vid{
					position: relative;
					z-index: 1;
				}
				.icon-close{
				    position:absolute;
				    top:10px;
				    right:10px;
				    width:20px;
				    height:20px;
					background-color: #FF0000;
					text-align: center;
					color: #fff;
				    //background:url("/img/close.png") center no-repeat;
				    background-size:100%;
				    cursor:pointer;
					z-index: 99999999;
				}
			}
	    }

.previewfile{	
	padding: 0;
	margin: 0;
}
.download{
	color: azure;
	margin-top: 15px;
	display: block;
	text-align: center;
}	
.download a{
	margin: 0 auto;
	display: inline-block;
	background-color: #FF0000;
	padding: 5px 8px;
	border-radius: 10px;
	font-size: 16px;
	font-weight: bold;
}
.previewBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(222, 222, 222, 0.3);
  z-index: 99999;
}
.drawer-close {
  position: absolute;
  top: 60px;
  right: 40px;
}
.pics{
		display: flex;
	    justify-content: center; /* 水平居中 */
	    align-items: center; /* 垂直居中 */
	    height: 100vh; /* 使容器的高度为视口高度 */
}
.pics img{
	 float: none;
	    display: block;
	    margin: 0 auto;
	}
.file{
	width: 80%;	
	min-height: 700px;
	margin:5% auto;
}
.docx-calss {
  height: calc(100vh - 60px);
}
</style>
