<template>
    <div>
        <audio id="chatAudio">
        <source src="../../assets/voice/notify.ogg" type="audio/ogg">
        <source src="../../assets/voice/notify.mp3" type="audio/mpeg">
        <source src="../../assets/voice/notify.wav" type="audio/wav">
        </audio>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Lockr from 'lockr'

    export default {
        name: "socket",
        data() {
            return {
                is_open_socket:false,
                websocket: null,
                pingInterval:30,
                connectNum:1,
                manMade:true,
                //心跳检测
		        timeout : 30000,//多少秒执行检测
		        heartbeatInterval : null, //检测服务器端是否还活着
		        reconnectTimeOut : null, //重连之后多久再次重连
            }
        },
        methods: {
            getWsUrl(){
                let ws_host=process.env.VUE_APP_BASE_API;
                //let ws_host='work.zw8.net';
                let protocol=window.location.protocol;
                let wsProtocol="ws://";
                if(process.env.NODE_ENV==='production'){
                    //ws_host=window.location.host;
                    ws_host='work.zw8.net'
                }
                // if(protocol=="https:"){
                //     wsProtocol="wss://";
                // }
                const WS_URI=wsProtocol+ws_host+"/wss";
                return WS_URI;
            },
            initWebSocket() { //初始化weosocket
                //ws地址
                const WS_URI = this.getWsUrl();
                this.websocket = new WebSocket(WS_URI);
                console.log('websocket',this.websocket);
                this.is_open_socket = true;
				this.websocket.onopen = this.onopen;
                this.websocket.onmessage = this.websocketOnMessage;
                this.websocket.onclose = this.websocketClose;
				this.websocket.onerror = this.onerror;
                Vue.prototype.$websocket = this.websocket;
				this.$global.setWs(this.websocket)
                this.$store.state.wsStatus = true;
				this.connectNum = 1;
				this.$global.setNo(1)
            },
			onerror(){
				this.reconnect()
			},
			onopen(){
				if(this.websocket.readyState ===1){
					this.websocket.send({"type":"ping"});
				}
				this.start();
			},
            websocketOnMessage(e) { //数据接收
                const data = JSON.parse(e.data);
                console.log('data',data['command']);
                if(data['client_id']) {
                    this.$api.commonApi.gatewayAPI({client_id: data['client_id']}).then(res=>{
                        Lockr.set('uuid',res.data);
                        //this.websocketSend({type:"bindUid",user_id:userInfo.user_id,token:token});
                        console.log(data['client_id'],'消息服务启动成功');
                    }).catch(error => {
                        console.log('连接失败');
                    })
                }
                let userInfo=Lockr.get('UserInfo');
                let token=Lockr.get('authToken');
                if(data['data']) {
                    if(data['command']) {
                        if(data['command'] == 'webmessage'){
                            Lockr.set('client_id',data['client_id']);                            
                            this.$api.commonApi.getWayMassageAPI({data: data,user_id:userInfo.user_id}).then(res=>{
                                if(res.code == 0) {
                                    this.$store.commit('catchSocketAction', res.data);
                                }
                                //this.websocketSend({type:"bindUid",user_id:userInfo.user_id,token:token});
                                console.log(data['client_id'],'消息服务启动成功');
                            }).catch(error => {
                                console.log('连接失败');
                            })
                        }else if(data['command'] == 'moveout' || data['command'] == 'wxbreak'){
							var that = this;	
							if (that.$global.ws && that.$global.ws.readyState == 1) {
									that.$global.ws.close();
									that.$global.setNo(0)
							}
							let msg = '提示信息';
							if(data['command'] == 'moveout'){
								msg = '当前账号被其他用户登录';
							}else if(data['command'] == 'wxbreak'){
								msg = '当前服务器企业微信自动退出，请联系管理员';
							}
							this.$confirm(msg, '提示', {
							  confirmButtonText: '确定',
							  type: 'warning',
							  showCancelButton:false
							}).then(() => {
							  this.$store.dispatch("LogOut").then(() => {
							    this.$router.push({ path: "/login" });
							  });
							});
							return;
						}else if(data['command'] == 'noserver'){
							this.$confirm('当前群成员服务器没挂PC端，是否发起更新群成员', '提示', {
							  confirmButtonText: '发起',
							  cancelButtonText: "取消",
							  type: 'warning',
							  distinguishCancelAndClose: true,
							  showCancelButton:true,
							  closeOnClickModal: false
							}).then(() => {
								this.$store.commit('catchUpdateGU', data['data']);
								this.$api.commonApi.initiateCommand(data['data']).then(res=>{
								   
								}).catch(error => {
								    console.log('连接失败');
								})
							}).catch(function (e) {
									if (e == 'cancel') {
									    // TODO 确认不通过执行逻辑									     
									} else if(e == 'close') {
									    // TODO 右上角X的执行逻辑									 
									}
							});
							return;			
						}else if(data['command'] == 'onlinelists'){
							this.$store.commit('catchOnlineLists', data['data']);
						}else if(data['command'] == 'updategroup'){
							this.$store.commit('catchUpdateGroup', data['data']);
						}else if(data['command'] == 'updategroupuser'){
							this.$store.commit('catchUpdateGroupUser', data['data']);
						}else if(data['data']['type']){
							if(data['data']['type'] == 'ping'){
							   this.websocketSend({type:"pong"});
							}
						}
                    }else{
                        this.$store.commit('catchSocketAction', data);
                    }
                }
                
                // switch (data['type']) {
                //     // 服务端ping客户端
                //     case 'ping':
                //         this.websocketSend({type:"pong"});
                //         break;
                //     // 登录 更新用户列表
                //     case 'init':
                //         Lockr.set('client_id',data['client_id']);
                //         this.$api.commonApi.bindClientIdAPI({client_id: data['client_id'],user_id:userInfo.user_id}).then(res=>{
                //             this.websocketSend({type:"bindUid",user_id:userInfo.user_id,token:token});
                //             console.log(data['client_id'],'消息服务启动成功');
                //         }).catch(error => {
                //             console.log('连接失败');
                //         })
                //         break;
                //     default:
                //         this.$store.commit('catchSocketAction', data);
                //         break;
                // }
            },
            websocketClose(e) {  //关闭
                console.log("websocket连接关闭")
                this.is_open_socket = false;
				if(this.$global.no == 0){
					return
				}
				clearInterval(this.heartbeatInterval)
				clearInterval(this.reconnectTimeOut)
				if (this.connectNum < 10) {
					this.manMade = false
					this.reconnect();
					this.connectNum += 1
                    return;
				} else {
                    this.$store.state.wsStatus = false;
					this.connectNum = 1;
                    this.websocket = null
				}
                let userInfo=Lockr.get('UserInfo');
                // this.$api.commonApi.offlineAPI({user_id:userInfo.user_id}).then(res=>{
                //     console.log("connection closed (" + e.code + ")");
                // })
            },
            start() {
				var that = this;
				that.heartbeatInterval && clearInterval(that.heartbeatInterval);
				that.reconnectTimeOut && clearInterval(that.reconnectTimeOut);
                that.heartbeatInterval = setInterval(() => {
					if(that.websocket.readyState == 1){
						that.websocketSend({
						    "type": "ping"
						});
					}else{
						that.reconnect();
					}                   
                }, that.timeout)
            },
            websocketSend(agentData) {//数据发送
			  var that = this;			  
			  var data=JSON.stringify(agentData);
			  if(that.websocket.readyState===1){
			      that.websocket.send(data);
			  }else{
				  that.initWebSocket();
				  that.websocketSend(agentData)
			  }
            },
            checkStatus(){
                if(!this.websocket || [2,3].includes(this.websocket.readyState)){
                    console.log("未链接！")
                    return false;
                }
                return true;
            },
            close() {
                if (!this.is_open_socket) {
                    return
                }
                this.websocket.close();
            },
            reconnect(){
				var that = this;
                console.log("正在重连...");
                //停止发送心跳
                clearInterval(that.heartbeatInterval)
				clearInterval(that.reconnectTimeOut);  
                //如果不是人为关闭的话，进行重连
                if (!that.is_open_socket && that.manMade == false ) {
                    console.log("5秒后重新连接...")
                    that.reconnectTimeOut = setInterval(() => {
                        that.initWebSocket();
                    }, 5000)
                }
            },
            playAudio () {
                const audio = document.getElementById('chatAudio');
                // 从头播放
                audio.currentTime = 0;
                audio.play();
            }
        },
        created() {
            this.initWebSocket()
        }
    }
</script>
