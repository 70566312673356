import request from '@/utils/request'

const commonApi = {}
commonApi.loginAPI= (params) =>{
    return request({
        url: 'wechat.php/pub/login',
        method: 'post',
        data: params
    })
}

commonApi.doAPI =(params)=>{
    return request({
        url: 'wechat.php/access/do',
        method: 'post',
        data: params
    })
}

commonApi.logoutAPI =(params)=>{
    return request({
        url: 'wechat.php/pub/logout',
        method: 'post',
        data: params
    })
}

// 绑定用户id以及更新client_id
commonApi.gatewayAPI= (params) =>{
    return request({
        url: 'wechat.php/im/gatewayUid',
        method: 'post',
        data: params
    })
}


// 检测是否软件端是否上线
commonApi.softAPI= (params) =>{
    return request({
        url: 'wechat.php/im/chkSoft',
        method: 'post',
        data: params
    })
}

// 检测是所有软件端是否上线
commonApi.softallAPI= (params) =>{
    return request({
        url: 'wechat.php/im/chkSoftAll',
        method: 'post',
        data: params
    })
}


// 绑定用户id以及消息接收
commonApi.getWayMassageAPI= (params) =>{
    return request({
        url: 'wechat.php/im/getWayMassage',
        method: 'post',
        data: params
    })
}

// 绑定用户id以及初始化消息服务
commonApi.bindClientIdAPI= (params) =>{
    return request({
        url: 'common/pub/bindUid',
        method: 'post',
        data: params
    })
}

// 用户下线
commonApi.offlineAPI= (params) =>{
    return request({
        url: 'common/pub/offline',
        method: 'post',
        data: params
    })
}

// 绑定群聊id
commonApi.bindGroupAPI= (params) =>{
    return request({
        url: 'common/pub/bindGroup',
        method: 'post',
        data: params
    })
}

// 发送验证码
commonApi.sendCode= (params) =>{
    return request({
        url: 'common/pub/sendCode',
        method: 'post',
        data: params
    })
}

// 获取系统配置信息
commonApi.getSystemInfo= (data)  =>{
    return request({
        url: 'wechat.php/pub/getSystemInfo',
        method: 'post',
        data: data
    })
}

// 注册用户
commonApi.register= (data)  =>{
    return request({
        url: 'common/pub/register',
        method: 'post',
        data: data
    })
}

// 上传头像
commonApi.uploadAvatar= (data)  =>{
    return request({
        url: 'wechat.php/upload/uploadAvatar',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

// 发起
commonApi.initiateCommand= (data)  =>{
    return request({
        url: 'wechat.php/pub/initiatecommand',
        method: 'post',
        data: data
    })
}

export default commonApi;