<template>
  <div id="app">
    <!-- <img src="./assets/logo.png"> -->
    <router-view/>
  </div>
</template>

<script>
import lockr from 'lockr';  

export default {
  name: 'App',
  data () {
    return {
		_beforeUnload_time:0,
		_gap_time:0,
		msg: 'Welcome to Your Vue.js App'
    }
  },
  created() {
    let userInfo=lockr.get('UserInfo');
    let Config=lockr.get('globalConfig');
    if(Config){
      document.title = Config.sysInfo.name;
      this.$store.commit('setGlobalConfig', Config);
    }
    // 初始化用户信息
    if(userInfo){
		console.log('vData',userInfo);
		this.$api.commonApi.softAPI({uid: userInfo.user_id}).then(res=>{		    
		    console.log('检测当前账号');
			if(res.code==0){
				let msg = "当前企业微信不在线，请让管理员处理";
				this.$confirm(msg, '提示', {
				  confirmButtonText: '确定',
				  type: 'warning',
				  showCancelButton:false
				}).then(() => {
				  this.$store.dispatch("LogOut").then(() => {
				    this.$router.push({ path: "/login" });
				  });
				});
			}
		}).catch(error => {
		    console.log('连接失败');
		})
		
		this.$store.commit('SET_USERINFO', userInfo);
    }
    this.$store.dispatch('getSystemInfo').then(res=>{
      if(res.data.sysInfo.state==0){
          this.$router.push({path:'/404',query:{msg:res.data.sysInfo.closeTips}});
          return 
      }
    });
  },
  watch: {
    "$store.state.globalConfig" (val) {
      document.title = val.sysInfo.name;
    }
  },
  methods:{
		/**
          * [beforeunloadHandler 浏览器关闭时进行用户提示]
          * @return {[type]} [description]
          */
         beforeunloadHandler(e) {
           this._beforeUnload_time=new Date().getTime();
         },
		//判断是窗口关闭还是刷新
		    unloadHandler(e){
				let userInfo=lockr.get('UserInfo');
				this._gap_time=new Date().getTime()-this._beforeUnload_time;
				if(this._gap_time<=5){
					//关闭要做的事情（这里可以发送请求，window.beforeunload事件在window.unload事件之前执行。同时注意ajax请求方式必须为同步请求，所以不能使用axios,因为axios不能执行同步请求,推荐使用navigator.sendBeacon。原因参考文章里有介绍）
					if(userInfo){
						this.$api.commonApi.logoutAPI({uuid: Lockr.get('uuid')}).then(res=>{		    
						    console.log('退出登陆');

						}).catch(error => {
						    console.log('连接失败');
						})
					}
				}else{
					//刷新要做的事情...
					console.log('刷新要做的事情....');
				}
		    }
  },
  mounted() {
	  //MDN一个基本跨浏览器运行的例子
	  window.addEventListener("beforeunload", function (e) {
	    var confirmationMessage = "您还有未保存的数据，确定要离开吗？"; //虽然可能没啥用
	    (e || window.event).returnValue = confirmationMessage;     //Gecko + IE
	    return confirmationMessage;                                //Webkit, Safari, Chrome etc.
	  });
	  window.addEventListener('unload', e => this.unloadHandler(e))
  },
  destroyed() {
        //组件销毁时清除窗体监听事件
        window.removeEventListener("beforeunload", e => {
          this.beforeunloadHandler(e);
        });
        window.removeEventListener('unload', e => this.unloadHandler(e))
  }
}
</script>

<style lang="scss">
@import "assets/scss/style";

  .lemon-container .lemon-container__title{
    border-bottom:solid 1px #e6e6e6 !important
  }
  .el-transfer-panel .el-transfer-panel__body {
    height: 280px !important; 
  }
  .lemon-editor__submit .lemon-button{
    background: var(--el-color-primary);
    color:#fff;
  }
   .lemon-editor__submit .lemon-button:hover{
    background: var(--el-color-primary);
    color:#fff !important;
    border:solid 1px var(--el-color-primary) !important;
  }
  .lemon-editor__submit button[disabled],.lemon-editor__submit button[disabled]:hover{
    background: #fff;
    color:#aaa;
    border:solid 1px #aaa;
  }
  body,html,#app{
    height: 100%;
    width:100%
  }

  .el-scrollbar{
      height: 100% !important;
  }

  .el-scrollbar__wrap {
      overflow-x: hidden !important;
  }

  .el-container{
    overflow: auto;
  }

  .el-select-dropdown__wrap {
    margin-bottom: 0 !important;
  }

  .lemon-contact{
    padding:10px;
  }
  

  hr {
    height: 1px;
    background-color: #e6e6e6;
    border: none;
  }


</style>
