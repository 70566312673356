var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lum-dialog-mask animated fadeIn"},[_c('el-container',{staticClass:"lum-dialog-box"},[_c('el-main',{staticClass:"no-padding mian"},[_c('div',{staticClass:"music"},[_c('span',{staticClass:"line line1",class:{ 'line-ani': _vm.animation }}),_c('span',{staticClass:"line line2",class:{ 'line-ani': _vm.animation }}),_c('span',{staticClass:"line line3",class:{ 'line-ani': _vm.animation }}),_c('span',{staticClass:"line line4",class:{ 'line-ani': _vm.animation }}),_c('span',{staticClass:"line line5",class:{ 'line-ani': _vm.animation }})]),_c('div',{staticStyle:{"margin-top":"35px","color":"#676262","font-weight":"300"}},[(_vm.recorderStatus == 0)?[_c('p',{staticStyle:{"font-size":"13px","margin-top":"5px"}},[_c('span',[_vm._v("语音消息，让聊天更简单方便 ...")])])]:(
            _vm.recorderStatus == 1 || _vm.recorderStatus == 2 || _vm.recorderStatus == 3
          )?[_c('p',[_vm._v(_vm._s(_vm.datetime))]),_c('p',{staticStyle:{"font-size":"13px","margin-top":"5px"}},[(_vm.recorderStatus == 1)?_c('span',[_vm._v("正在录音...")]):(_vm.recorderStatus == 2)?_c('span',[_vm._v("已暂停录音")]):(_vm.recorderStatus == 3)?_c('span',[_vm._v("录音时长")]):_vm._e()])]:(
            _vm.recorderStatus == 4 || _vm.recorderStatus == 5 || _vm.recorderStatus == 6
          )?[_c('p',[_vm._v(_vm._s(_vm.formatPlayTime))]),_c('p',{staticStyle:{"font-size":"13px","margin-top":"5px"}},[(_vm.recorderStatus == 4)?_c('span',[_vm._v("正在播放")]):(_vm.recorderStatus == 5)?_c('span',[_vm._v("已暂停播放")]):(_vm.recorderStatus == 6)?_c('span',[_vm._v("播放已结束")]):_vm._e()])]:_vm._e()],2)]),_c('el-footer',{staticClass:"footer",attrs:{"height":"60px"}},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.recorderStatus == 0),expression:"recorderStatus == 0"}],attrs:{"type":"primary","size":"small","round":"","icon":"el-icon-microphone"},on:{"click":_vm.startRecorder}},[_vm._v("开始录音 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.recorderStatus == 1),expression:"recorderStatus == 1"}],attrs:{"type":"warning","size":"small","round":"","icon":"el-icon-video-pause"},on:{"click":_vm.pauseRecorder}},[_vm._v("暂停录音 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.recorderStatus == 2),expression:"recorderStatus == 2"}],attrs:{"type":"primary","size":"small","round":"","icon":"el-icon-microphone"},on:{"click":_vm.resumeRecorder}},[_vm._v("继续录音 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:((_vm.recorderStatus == 2 || _vm.recorderStatus == 1) && _vm.duration>1),expression:"(recorderStatus == 2 || recorderStatus == 1) && duration>1"}],attrs:{"type":"danger","size":"small","round":"","icon":"el-icon-microphone"},on:{"click":_vm.stopRecorder}},[_vm._v("结束录音 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.recorderStatus == 3 || _vm.recorderStatus == 6),expression:"recorderStatus == 3 || recorderStatus == 6"}],attrs:{"type":"success","size":"small","round":"","icon":"el-icon-video-play"},on:{"click":_vm.playRecorder}},[_vm._v("播放录音 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.recorderStatus == 3 || _vm.recorderStatus == 5 || _vm.recorderStatus == 6
        ),expression:"\n          recorderStatus == 3 || recorderStatus == 5 || recorderStatus == 6\n        "}],attrs:{"type":"warning","size":"small","round":"","icon":"el-icon-refresh-right"},on:{"click":_vm.startRecorder}},[_vm._v("重新录音 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.recorderStatus == 4),expression:"recorderStatus == 4"}],attrs:{"type":"info","size":"small","round":"","icon":"el-icon-video-pause"},on:{"click":_vm.pausePlayRecorder}},[_vm._v("暂停播放 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.recorderStatus == 5),expression:"recorderStatus == 5"}],attrs:{"type":"success","size":"small","round":"","icon":"el-icon-video-play"},on:{"click":_vm.resumePlayRecorder}},[_vm._v("继续播放 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.recorderStatus == 3 || _vm.recorderStatus == 5 || _vm.recorderStatus == 6
        ),expression:"\n          recorderStatus == 3 || recorderStatus == 5 || recorderStatus == 6\n        "}],attrs:{"type":"primary","size":"small","round":""},on:{"click":_vm.submit}},[_vm._v("立即发送 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }